// $font_path: "./fonts/";
$font_path: 'https://barnes-live.s3.amazonaws.com/prod/assets/fonts/';

@font-face {
	font-family: 'Calibre';
	src: url($font_path + 'CalibreWeb-Regular.woff2') format('woff2'),
		url($font_path + 'CalibreWeb-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calibre';
	src: url($font_path + 'CalibreWeb-Medium.woff2') format('woff2'),
		url($font_path + 'CalibreWeb-Medium.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'FFMiloSerifWebText';
	src: url($font_path + '3399DB_0_0.woff2') format('woff2'),
		url($font_path + '3399DB_0_0.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FFMiloSerifWebText';
	src: url($font_path + '3399DB_1_0.woff2') format('woff2'),
		url($font_path + '3399DB_1_0.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
