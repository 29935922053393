// Breakpoint information, where each starts and stops
// if a breakpoint is not fluid, then the start value is equal to the main col value plus 2x the gutter at this breakpoint
$breakpoints: (
	xsmall: (
		start: null,
		end: 413,
	),
	small: (
		start: 414,
		end: 649,
	),
	medium: (
		start: 650,
		end: 989,
	),
	mediuml: (
		start: 730,
		end: 989,
	),
	large: (
		start: 990,
		end: 1199,
	),
	xlarge: (
		start: 1200,
		end: 1599,
	),
	xxlarge: (
		start: 1600,
		end: null,
	),
);

/*
@function get-breakpoint-directions
Sorts through breakpoints SASS map,
generates a full SASS map containing all the breakpoint
variations we'll require
Parameters:
none
*/
@function get-breakpoint-directions() {
	$_bps: ();
	@each $k, $bp in $breakpoints {
		$_bps: map-merge(
			$_bps,
			(
				$k: $bp,
			)
		);
		$start: map-get($bp, start);
		$end: map-get($bp, end);

		@if $end != null and $start != null {
			$down-key: unquote($k + '-');
			$_bps: map-merge(
				$_bps,
				(
					$down-key: (
						start: null,
						end: $end,
					),
				)
			);
		}

		@if $start != null and $end != null {
			$up-key: unquote($k) + '+';
			$_bps: map-merge(
				$_bps,
				(
					$up-key: (
						start: $start,
						end: null,
					),
				)
			);
		}
	}

	@return $_bps;
}

$breakpoints-with-directions: get-breakpoint-directions();

/*
@mixin breakpoint
Inserts a media query
Parameters:
$name - name of breakpoint, choose from:
1
xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
2
small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
3
xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
4
Apply CSS upto the large+ breakpoint to also be Print CSS
NB: the we're mobile up, so the minus values should be avoided..
*/

@mixin breakpoint($name) {
	$points: map-get($breakpoints-with-directions, $name);
	@if $points {
		$media: get-media($points);
		$start: map-get($media, 'start');
		$end: map-get($media, 'end');
		$str: 'screen and (';

		// 1
		@if ($start != null) {
			$str: $str + 'min-width: ' + ($start * 1px);
		}

		// 2
		@if ($start != null and $end != null) {
			$str: $str + ') and (';
		}
		// 3
		@if ($end != null) {
			$str: $str + 'max-width: ' + ($end * 1px);
		}
		$str: $str + ')';
		// 4
		@if ($end == null and $start != null and $name != 'xlarge+') {
			$str: 'print, ' + $str;
		}

		@media #{$str} {
			@content;
		}
	} @else {
		@warn "Unknown breakpoint `#{$name}` in $breakpoints.";
	}
}

/*
@function get-media
Returns start and stop points of a given media query
Parameters:
$bp - the breakpoint you want the stop and stop points of
*/

@function get-media($bp) {
	$start: null;
	$end: null;

	$bp-start: map-get($bp, 'start');
	$bp-end: map-get($bp, 'end');
	@if ($bp-start != null and ($start == null or $bp-start < $start)) {
		$start: $bp-start;
	}
	@if ($bp-end != null and ($end == null or $bp-end > $end)) {
		$end: $bp-end;
	}

	@return (start: $start, end: $end);
}
