@import '../variables.scss';
@import '../mixins/breakpoints.scss';

.error {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $white;
    padding: 30px;

    @include breakpoint('mediuml+') {
        padding: 75px;
    }

    &__sad-face {
        max-width: 140px;
        display: block;
    }
    
    &__text {
        margin-top: 30px;
        font-size: 18px;
        color: $color__copy--medium;
    }
}

