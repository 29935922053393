@import '../variables.scss';
@import '../mixins/breakpoints.scss';

$home-z-indexes: (
	background: 1,
	overlay: 2,
	content: 3,
);

.home {
	z-index: map-get($home-z-indexes, 'content');

	font-family: $font-family__sans-serif;
	color: #ffffff;
	text-align: center;

	width: 100%;
	height: 100%;

	padding: 30px 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	@include breakpoint('medium+') {
		padding: 0 0 40px;
		width: 75%;
		max-width: 600px;
	}

	@include breakpoint('large+') {
		width: 50%;
		max-width: 600px;
	}

	&__flex {
		z-index: map-get($home-z-indexes, 'content');

		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	&__overlay {
		z-index: map-get($home-z-indexes, 'overlay');

		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		background-color: #000;
		opacity: 0.65;

		@include breakpoint('medium+') {
			opacity: 0.6;
		}
	}

	&__background {
		z-index: map-get($home-z-indexes, 'background');

		position: absolute;
		top: 0;
		left: 0;

		height: 100%;

		@include breakpoint('mediuml+') {
			height: auto;
			width: 100%;
		}
	}

	&__content {
		animation: fadeIn 5s;
	}

	&__header {
		font-size: 36px;
		margin: 10px 0 0;
	}

	&__sub-header {
		font-size: 36px;
		margin: 0px 0 5px;
		font-weight: 400;

		@include breakpoint('medium+') {
			margin: 0 0 15px;
		}

		&--small {
			font-size: 18px;
			margin: 0px 0 15px;
		}

		&--top {
			@include breakpoint('medium+') {
				display: none;
			}
		}

		&:after {
			content: ' ';
			display: block;
		}
	}

	&__header,
	&__sub-header {
		opacity: 1;
		transition: opacity 200ms;

		&--hidden {
			opacity: 0;
		}
	}

	&__copy {
		font-family: $font-family__serif;
		font-size: 22px;
		max-width: 770px;
		margin: 0 0 25px;
		line-height: 1.75rem;

		a {
			color: $white;
			text-decoration: underline;

			&:hover {
				color: $color__copy--light;
			}
		}
	}

	&__logo {
		color: $white;
		display: inline-block;
		max-width: 200px;
		margin: 50px auto;
	}

	&__call {
		width: 100%;
		line-height: 60px;

		font-family: $font-family__sans-serif;
		font-weight: 700;
		font-size: 19px;

		background-color: $color__red--barnes;
		border: 2px solid $color__red--barnes;

		color: $white;
		border-radius: 0;

		cursor: pointer;
		display: inline-block;
		height: 60px;
		padding: 0 30px;
		text-align: center;
		text-decoration: none;
		transition: all 0.25s ease 0.1s;
		white-space: nowrap;

		padding: 0;

		transition: color 0.5s, background-color 0.5s, border-color 0.5s;

		&:active,
		&:hover {
			color: inherit;
			border-color: $color__red--barnes-dark;
			background-color: $color__red--barnes-dark;
		}

		margin: 20px 0 5px;

		@include breakpoint('medium+') {
			max-width: 300px;
			margin: 30px auto 20px;
		}
	}

	&__zeta {
		color: $white;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 18px;
		letter-spacing: 0.04em;
		line-height: 1.5625;

		&:hover {
			color: $white;
		}
	}
}
