@import './variables.scss';

html,
body,
#root {
	height: 100%;
}

* {
	box-sizing: border-box;
}

a {
	color: $color__copy--light;
	text-decoration: underline;

	&:hover {
		color: $color__copy--dark;
	}
}
