@import './typography.scss';

//////////////
/// COLORS ///
//////////////
$white: #fff;
$black: #000;
$red: #ff0000;
$orange: #d64220;
$blue: #22334a;
$darkblue: #1b2a3c;
$darkgrey: #777777;
$darkestgrey: #1c1c1c;
$grey: #dcdcdc;
$light-blue: #0058db;

$color__copy--light: #8c8c8c;
$color__copy--dark: #282828;

$color__grey--light: #f7f7f7;
$button-color: $color__grey--light;
$button-color--selected: $orange;
$button-border-color: #c6c2b9;
$color__copy--medium: #6e6e6e;

$button-border: 1px solid $button-border-color;
$border-space: 1px solid #e8e8e8;

$color__red--barnes: #d64220;
$color__red--barnes-dark: #b3371b;
$color__nav--hover: #e68e79;

/////////////////
/// Z-INDEXES ///
/////////////////
$z-indexes: (
	content: 1,
	banner-image: 1,
	banner-text: 2,
	title-bar: 3,
	menu-overlay: 4,
	menu: 5,
);

///////////////////
/// FONT FAMILY ///
///////////////////
$font-family__sans-serif: Calibre, sans-serif;
$font-family__serif: FFMiloSerifWebText, serif;

///////////////////
/// DIMENSIONS ///
//////////////////
$dimension__footer: 120px;
