@import '../variables.scss';
@import '../mixins/breakpoints.scss';

.app {
	min-height: 100%;
	display: flex;
	flex-direction: column;

	&__content {
		z-index: map-get($z-indexes, 'content');
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;

		align-items: center;
		justify-content: center;

		max-width: 100vw;
		overflow: hidden;
		position: relative;

		@include breakpoint('medium+') {
			flex: 1 0 0;
		}
	}

	&__footer {
		flex-shrink: 0;
	}
}
